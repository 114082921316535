//Order Module
export const SET_DATE = 'setDate'
export const ADD_BOOKING = 'addBooking'
export const SET_ID = 'setOrderId'
export const SET_COST = 'setCost'
export const SET_DISCOUNT = 'setDiscount'
export const SET_PAYTYPE = 'setPayType'
export const SET_CLIENT = 'setClient'
export const SET_TYPE = 'setType'
export const SET_STATUS = 'setStatus'
export const REPLACE_BOOKING = 'replaceBooking'
export const SET_ORDER = 'setOrder'
export const ADD_DEPOSIT = 'addDeposit'
export const UPDATE_ORDER = 'updateOrder'
export const SET_COMPANY = 'setCompany'

//Booking Module
export const SET_BOOKINGID = 'setBookingId'
export const SET_BOOKINGDISCOUNT = 'setBookingDiscount'
export const ADD_BOOKINGDISCOUNT = 'addBookingDiscount'
export const SET_BOOKINGDATE = 'setBookingDate'
export const SET_BOOKINGTIME = 'setBookingTime'
export const SET_BOOKINGFIELD = 'setBookingField'
export const SET_BOOKINGPAID = 'setBookingPaid'
export const SET_BOOKINGPRICE = 'setBookingPrice'
export const SET_DURATION = 'setDuration'
export const SET_BOOKING = 'setBooking'
export const SET_DESCRIPTION = 'setDescription'
export const SET_FACILITYID = 'setFacilityId'
export const UPDATE_BOOKING = 'updateBooking'
export const SET_FIELDNAME = 'setPlayfieldName'
//Facility Module
export const SET_FID = 'setFacilityId'
export const SET_NAME = 'setFacilityName'
export const SET_COUNTRY = 'setFacilityCountry'
export const SET_COORDINATES = 'setFacilityCoordinates'
export const SET_FDESCRIPTION = 'setFacilityDescription'
export const SET_AVATAR = 'setFacilityAvatar'
export const SET_PFNUM = 'setFacilityNumber'
export const SET_PLAYFIELDS = 'setFacilityPlayfields'
export const ADD_PLAYFIELD = 'addFacilityPlayfield'
export const SET_CRMUSER = 'setFacilityAdmin'
export const SET_INFR = 'setFacilityInfr';
export const SET_PAYMENTCONDITION = 'setPaymentCondition';
export const SET_FACILITY = 'setFacility';
export const SET_CONTACTS = "setFacilityContacts"
//Playfield Module
export const SET_PNAME = 'setPlayfieldName'
export const SET_SPECIFICATIONS  = 'setSpecifications'
export const SET_PLAYFIELD = 'setPlayfield'
export const SET_INDEX = 'setPlayfieldIndex'
//Spinner Module
export const SET_START = 'setStart'