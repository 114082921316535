import { render, staticRenderFns } from "./ErrorCard.vue?vue&type=template&id=1dc2e042&scoped=true&"
import script from "./ErrorCard.vue?vue&type=script&lang=js&"
export * from "./ErrorCard.vue?vue&type=script&lang=js&"
import style0 from "./ErrorCard.vue?vue&type=style&index=0&id=1dc2e042&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dc2e042",
  null
  
)

export default component.exports